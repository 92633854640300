import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/app/src/templates/PageLocal.tsx";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const QuoteCarousel = makeShortcode("QuoteCarousel");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "sem-tsa-targeted-sales-activities"
    }}>{`SEM TSA (Targeted Sales Activities)`}</h1>
    <h2 {...{
      "id": "what-do-sales-reps-say-about-tsa"
    }}>{`What do Sales Reps say about TSA?`}</h2>
    <QuoteCarousel quotes={[{
      quote: "“Before going to visit a client, I consult the TSA information in order to have a head start on the client in the negotiation.”",
      author: "DRC"
    }, {
      quote: "“To be able to see future opportunities and to identify gaps in the outlet. It is also a tool that one can use to engage with the customer in terms of numbers.”",
      author: "South Africa"
    }, {
      quote: "“It guides me to check or ask the customer about missed products”",
      author: "Egypt"
    }, {
      quote: "“I use TSA to learn how frequent customer is ordering and what SKU, hence proposing the right sales”",
      author: "Malaysia"
    }, {
      quote: "Actions and opportunities help us take steps decisions that can help us increase EOE",
      author: "Indonesia"
    }, {
      quote: "When we met the customer, TSA showed and told the outlet information, he did not order for a long time so we can find a way to boost his sales more effectively!",
      author: "Cambodia"
    }]} mdxType="QuoteCarousel" />
    <h2 {...{
      "id": "targeted-sales-activities"
    }}>{`Targeted Sales Activities`}</h2>
    <h3 {...{
      "id": "what-is-tsa"
    }}>{`What is TSA?`}</h3>
    <p>{`TSA are next best actions, generated from data, to empower a sales rep or drivers with insights on outlet’s Order Behaviour and PICOS!  From millions of transactions to one valuable specific activity. By having a tailored approach per outlet,  we can improve customer experience and accelerate customer growth.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "69.33333333333334%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "tsa",
          "title": "tsa",
          "src": "/static/506e2d35062899626c39aae33d276adc/c1b63/tsa.png",
          "srcSet": ["/static/506e2d35062899626c39aae33d276adc/5a46d/tsa.png 300w", "/static/506e2d35062899626c39aae33d276adc/0a47e/tsa.png 600w", "/static/506e2d35062899626c39aae33d276adc/c1b63/tsa.png 1200w", "/static/506e2d35062899626c39aae33d276adc/d61c2/tsa.png 1800w", "/static/506e2d35062899626c39aae33d276adc/f0293/tsa.png 2250w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "value-case--rwanda-pilot---120-outlets---12-weeks"
    }}>{`Value Case | Rwanda Pilot - 120 outlets - 12 weeks`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "16%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "valuecase",
          "title": "valuecase",
          "src": "/static/aea3cf64bd8fce7288218078657ce999/c1b63/valuecase.png",
          "srcSet": ["/static/aea3cf64bd8fce7288218078657ce999/5a46d/valuecase.png 300w", "/static/aea3cf64bd8fce7288218078657ce999/0a47e/valuecase.png 600w", "/static/aea3cf64bd8fce7288218078657ce999/c1b63/valuecase.png 1200w", "/static/aea3cf64bd8fce7288218078657ce999/d61c2/valuecase.png 1800w", "/static/aea3cf64bd8fce7288218078657ce999/764a0/valuecase.png 2025w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "how-does-it-work"
    }}>{`How does it work?`}</h3>
    <ol>
      <li parentName="ol">{`The Sales Rep reviews generated TSAs to determine focus and prepare for outlet visit`}</li>
      <li parentName="ol">{`The Sales Rep can have a tailor-made conversation with the Customer based on TSA insights`}</li>
      <li parentName="ol">{`The Sales Rep provides TSA outcome data that is shared with the Sales Manager for continuous improvement`}</li>
    </ol>
    <h3 {...{
      "id": "live-opcos--13"
    }}>{`Live OpCo's | 13`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "26.333333333333336%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "liveopcos",
          "title": "liveopcos",
          "src": "/static/d48c10c0d32de0035b48933108c479db/c1b63/liveopcos.png",
          "srcSet": ["/static/d48c10c0d32de0035b48933108c479db/5a46d/liveopcos.png 300w", "/static/d48c10c0d32de0035b48933108c479db/0a47e/liveopcos.png 600w", "/static/d48c10c0d32de0035b48933108c479db/c1b63/liveopcos.png 1200w", "/static/d48c10c0d32de0035b48933108c479db/d61c2/liveopcos.png 1800w", "/static/d48c10c0d32de0035b48933108c479db/97a96/liveopcos.png 2400w", "/static/d48c10c0d32de0035b48933108c479db/ab199/liveopcos.png 2603w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "ongoing-deployments--3"
    }}>{`Ongoing Deployments | 3`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "517px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "28.333333333333332%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "ongoing",
          "title": "ongoing",
          "src": "/static/6edd5239f81d9b02babeb5fde4fe57d1/fa2f5/ongoing.png",
          "srcSet": ["/static/6edd5239f81d9b02babeb5fde4fe57d1/5a46d/ongoing.png 300w", "/static/6edd5239f81d9b02babeb5fde4fe57d1/fa2f5/ongoing.png 517w"],
          "sizes": "(max-width: 517px) 100vw, 517px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    <h3 {...{
      "id": "tsa-user-survey--6-opcos---178-sales-reps"
    }}>{`TSA User Survey | 6 OpCo’s - 178 Sales Reps`}</h3>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "64.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "usersurvey",
          "title": "usersurvey",
          "src": "/static/de01e056de7c6731ff7cd4e20127b499/c1b63/usersurvey.png",
          "srcSet": ["/static/de01e056de7c6731ff7cd4e20127b499/5a46d/usersurvey.png 300w", "/static/de01e056de7c6731ff7cd4e20127b499/0a47e/usersurvey.png 600w", "/static/de01e056de7c6731ff7cd4e20127b499/c1b63/usersurvey.png 1200w", "/static/de01e056de7c6731ff7cd4e20127b499/71d2f/usersurvey.png 1485w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span></p>
    {/* ### What do Sales Reps say about TSA?
     “Before going to visit a client, I consult the TSA information in order to **have a head start** on the client in the **negotiation**.” DRC
     “To be able to **see future opportunities** and to identify gaps in the outlet. It is also a tool that one can use to **engage with the customer** in terms of numbers.” South Africa
     “**It guides me** to check or ask the customer about missed products” Egypt
     “I use TSA to learn how frequent customer is ordering and what SKU, hence **proposing the right sales**” Malaysia
     “Actions and opportunities **help us take steps/ decisions** that can help us **increase EOE**.” Indonesia
     “When we met the customer, TSA showed and told the outlet information, he did not order for a long time so we can find a way to **boost his sales more effectively!**” Cambodia
     <QuoteCarousel /> */}

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      